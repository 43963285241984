import Api, { ApiTypes } from "api";
import { toast } from "react-toastify";
import { thunkService } from "services";
import Dependencies from "shared/app/dependencies";
import { EntityStatus } from "shared/global/enums";
import getAccountNo from "shared/utils/getAccountNo";
import getTokenWithBearer from "shared/utils/getTokenWithBearer";
import { ApiResponse } from "../../shared/api/constants";


class AccountService {
    private readonly _dispatch;
    private readonly _storeManager;
    private readonly _tradingAccountService;
    private readonly _profileServiceProxy;
    
    constructor(dependencies: Dependencies.Root) {
        this._dispatch = dependencies.dispatch;
        this._storeManager = dependencies.storeManager;
        this._tradingAccountService = new Api.TradingAccountsServiceProxy();
        this._profileServiceProxy = new Api.ProfileServiceProxy();
    }

    public changePassword = async (input: ApiTypes.ChangePasswordInput) => {
        return (this._profileServiceProxy.changePassword(input)) as any;
    }

    public createDemoTradingAccount = async (tradingPlatformIndex: number) => {
        const loginInformations = {...this._storeManager.account.getLoginInformations()};
        const tradingPlatform = (loginInformations.data.tenantSummary as ApiTypes.TenantSummaryDto).tradingPlatforms?.[tradingPlatformIndex];
        

        const input = new Api.CreateTradingAccountInputDto({
            platformCode: tradingPlatform?.code || ''
        });

        const response: ApiResponse<ApiTypes.CreateTradingAccountOutputDto> = await this._tradingAccountService.createTradingAccount(input) as any;
        if (response.success) {
            const { accountNo, platformCode } = response.data;

            if (input.platformCode === platformCode) {
                tradingPlatform?.logins?.push(accountNo);
                this._storeManager.account.setLoginInformations(loginInformations);

            } else {
                throw new Error(`ERROR! Expected platform code is not matched. Expected: ${input.platformCode}, Response: ${platformCode}`);
            }

        } else {
            const message = response.message;
            toast(message);
        }
    }

    /** Description: Returns the trading account numbers. */
    public getLogins = () => {
        const result: { code: string, login: number }[] = [];
        const loginInformations = this._storeManager.account.getLoginInformations();
        if (loginInformations.status === EntityStatus.SUCCEEDED) {
            const loginInformationsData = loginInformations.data;
            const tenantSummary = loginInformationsData.tenantSummary as ApiTypes.TenantSummaryDto;

            tenantSummary.tradingPlatforms?.forEach((tradingPlatform) => 
                tradingPlatform.logins?.forEach((login) => {
                    result.push({ login, code: tradingPlatform.code as string });
                })
            )
        }   

        return result;
    }

    /** Description: If login length is one, logs in the account automatically. */
    public loginIfHasOnlyOneTradingAccount = (callback: (tradingAccount: { code: string, login: number }) => void) => {
        const tradingAccounts = this.getLogins();
        if (tradingAccounts.length === 1) {
            callback(tradingAccounts[0])
            return true;
        }

        return false;
    }

    /** Description: Returns the user account informations. */
    public getLoginInformations = (): Promise<any> => {
        return new Promise((resolve, _) => {
            if (getTokenWithBearer()) {
                const loginInformations = this._dispatch(thunkService.account.getLoginInformations());
                resolve(loginInformations);
            }    

            resolve(undefined);
        })
    }

    /** Description: Returns the trading account informations. */
    public getTradingAccountInformations = (input: { platformCode: string, accountNo: number }) => {
        return this._dispatch(thunkService.account.getTradingAccountInfo(input));
    }

    /** Description: If has trading account in localstorage, sets it to store. */
    public storeStorageValues() {
        const accountNo = getAccountNo();
        if (accountNo) {
            this._storeManager.account.setAccountNo(accountNo);
        }
    }
}

export default AccountService;