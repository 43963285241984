import { EnPositionAction, PositionDto } from "../../../api/service-proxies";
import { ExposureToolData } from "./types";

class ExposureToolHelper {
    public getData = (positions: PositionDto[]) => {
        const result: {[property: string]: ExposureToolData} = {};

        if (positions?.length) {
            positions.forEach(position => {
                const classItem: ExposureToolData = {
                    ...result[position.symbol!], 
                    symbol: position.symbol!, 
                    digits: position.digits 
                }

                if (position.type === EnPositionAction.POSITION_BUY) {
                    classItem.buyDeals = classItem.buyDeals ? classItem.buyDeals + 1 : 1;
                    classItem.buyPrice = classItem.buyPrice ? classItem.buyPrice + position.priceOpen : position.priceOpen;
                    classItem.buyLots = classItem.buyLots ? classItem.buyLots + position.volume : position.volume;
                    classItem.buyProfit = classItem.buyProfit ? classItem.buyProfit + position.profit : position.profit;
                } else {
                    classItem.sellDeals = classItem.sellDeals ? classItem.sellDeals + 1 : 1;
                    classItem.sellPrice = classItem.sellPrice ? classItem.sellPrice + position.priceOpen : position.priceOpen;
                    classItem.sellLots = classItem.sellLots ? classItem.sellLots + position.volume : position.volume;
                    classItem.sellProfit = classItem.sellProfit ? classItem.sellProfit + position.profit : position.profit;
                }

                result[position.symbol!] = classItem;
            })
        }

        return result;
    }
}

const exposureToolHelper = new ExposureToolHelper();
export default exposureToolHelper;