import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { SymbolState } from "./types";
import { thunkService } from "services";
import { CIMTConGroupDto, CIMTConSymbolDto } from "../../../api/service-proxies";
import { SymbolFeedData } from "../../../shared/symbol/types";

import storageManager from 'modules/storage-manager';

class SymbolThunk {
    /** Description: Adds the "Symbol" method cases.  */
    public addCases = (builder: ActionReducerMapBuilder<SymbolState>) => {
        this.addGetTradingAccountInfoCase(builder);
        this.addLoginInformationsCase(builder);
    }

    /** Description: Adds the "getTradingAccountInfo" method cases. */
    private addGetTradingAccountInfoCase = (builder: ActionReducerMapBuilder<SymbolState>) => {
        // Event: onPending
        builder.addCase(thunkService.account.getTradingAccountInfo.pending, (state, action) => {
            
        });

        // Event: onRejected
        builder.addCase(thunkService.account.getTradingAccountInfo.rejected, (state, action) => {
            
        });

        // Event: onFulfilled
        builder.addCase(thunkService.account.getTradingAccountInfo.fulfilled, (state, action) => {
            this.setSymbolState(state, action);
        });
    }

    /** Description: Adds the "getLoginInformations" method cases. */
    private addLoginInformationsCase = (builder: ActionReducerMapBuilder<SymbolState>) => {
        // Event: onFulfilled
        builder.addCase(thunkService.account.getLoginInformations.fulfilled, (state, action) => {
            this.setSymbolState(state, action);
        });
    }

    setSymbolState(state: SymbolState, action:any) {

        const { group, defaultWatchListSymbols, platformCode, accountNo } = action.payload as any;

        state.defaultWatchListSymbols = defaultWatchListSymbols;
        state.group = group;

        const storageWatchList = storageManager.preferences.get()?.watchList || {};

        let willBeSetWathcList = new Array<string>();

        if (!storageWatchList?.[platformCode]?.[accountNo]?.length) {
            willBeSetWathcList = defaultWatchListSymbols;
        }
        else {
            storageWatchList?.[platformCode]?.[accountNo].forEach(_symbol => {
                const foundSymbol = (group as CIMTConGroupDto)?.symbols!.find(f => f.symbol === _symbol)
                if (foundSymbol !== undefined) { willBeSetWathcList.push(_symbol); }
            })
        }

        storageManager.preferences.update({
            watchList: {
                ...storageWatchList,
                [platformCode]: {
                    ...storageWatchList[platformCode],
                    [accountNo]: willBeSetWathcList
                }
            }
        });

        let data: { [key: string]: SymbolFeedData } = {};
        let watchList: string[] = []; 

        if (group !== undefined && group.symbols.length > 0) {

            willBeSetWathcList.forEach(_symbol => {
                const symbol = (group.symbols as CIMTConSymbolDto[]).find(f => f.symbol === _symbol);

                if (symbol !== undefined) {
                    data[symbol.symbol!] = { symbol: symbol.symbol, bid: symbol.bid, ask: symbol.bid, digits: symbol.digits, point: symbol.point, dif: symbol.spreadDiff, high: symbol.high, low: symbol.low } as SymbolFeedData;
                    watchList.push(symbol.symbol!); 
                }
            })
        }
     
        state.data = data;
        state.watchList = watchList;
    }
}

const symbolThunk = new SymbolThunk();
export default symbolThunk;
