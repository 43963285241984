import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
// Types
import { OrderPositionDealState } from "./types";
import { thunkService } from "services";

class OrderThunk {
    /** Description: Adds the "Order" method cases.  */
    public addCases = (builder: ActionReducerMapBuilder<OrderPositionDealState>) => {
        this.addOrderHistoryCase(builder);
        this.addTradingAccountInfoCase(builder);
        this.addLoginInformationsCase(builder);
    }

    /** Description: Adds the "getOrderHistory" method cases.  */
    private addOrderHistoryCase = (builder: ActionReducerMapBuilder<OrderPositionDealState>) => {
        // Event: onPending
        builder.addCase(thunkService.order.getOrderHistory.pending, (state, action) => {
            
        });

        // Event: onRejected
        builder.addCase(thunkService.order.getOrderHistory.rejected, (state, action) => {
            
        });

        // Event: onFulfilled
        builder.addCase(thunkService.order.getOrderHistory.fulfilled, (state, action) => {
            state.deals = action.payload as any;
        });
    }

    /** Description: Adds the "getTradingAccountInfo" method cases.  */
    private addTradingAccountInfoCase = (builder: ActionReducerMapBuilder<OrderPositionDealState>) => {
        // Event: onFulfilled
        builder.addCase(thunkService.account.getTradingAccountInfo.fulfilled, (state, action) => {
            const payload = action.payload as any;
            const tradingAccountInfo = payload.tradingAccountInfo;
            if (tradingAccountInfo) {
                state.positions = tradingAccountInfo.positions;
                state.orders = tradingAccountInfo.orders;
            }
        });
    }
    
    /** Description: Adds the "getLoginInformations" method cases.  */
    private addLoginInformationsCase = (builder: ActionReducerMapBuilder<OrderPositionDealState>) => {
        // Event: onFulfilled
        builder.addCase(thunkService.account.getLoginInformations.fulfilled, (state, action) => {
            const { tradingAccountInfo } = action.payload as any;
            if (tradingAccountInfo) {
                state.positions = tradingAccountInfo.positions;
                state.orders = tradingAccountInfo.orders;
            }
        })
    }
}

const orderThunk = new OrderThunk();
export default orderThunk;