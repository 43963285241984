import React from 'react';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
// Components
import Modal from 'components/modal';
// Types
import { RootState } from 'store/types';
import { ModalPositionClose } from 'store/slices/modal-slice/types';
import useTranslation from 'modules/localization';
import { positionService } from 'services';
import { AppOneClickTrading } from 'store/slices/app-slice/types';
import { PositionActions } from '../../../shared/trade/enums';

interface Props {
    modalData: ModalPositionClose,
    oneClickTrading: AppOneClickTrading
}

const PositionCloseModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    const positionData = props.modalData.data;
    const { position, symbol, type, volume } = positionData;

    const handleClose = () => storeManager.modal.setPositionClose({ visibility: false, data: null })

    const changePositionData = (property: string, value: any) => {
        storeManager.modal.setPositionClose({
            ...props.modalData, 
            data: { 
                ...positionData,
                [property]: value 
            }
        })
    }

    const changeVolume = (volume: string) => {
        const decimalCount = volume.replace('.', ',').split(',')?.[1]?.length || 0;

        if (decimalCount <= 2) {
            changePositionData('volume', volume)
        }
    }

    const handleSubmitClick = () => {
        if (!serviceAvailable) return;

        setServiceAvailable(false);

        positionService.provider.close()
            .then(() => handleClose())
            .finally(() => setServiceAvailable(true));
    }

    // one click trading 
    if (props.oneClickTrading) {
        positionService.provider.close()
            .finally(() => handleClose())

        return null;
    }

    return (
        <Modal title={t('ClosePosition')} id="position-close-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                <div className="description">
                    #{position} {PositionActions[type]}
                </div>
                <div className="volume">
                    <label htmlFor="position-volume">{t('Volume')}</label>
                    <input type="number" id="position-volume" step="0.01" min="0.01" value={volume} onChange={(e) => changeVolume(e.currentTarget.value)} />
                </div>
                <button type="submit" className="submit-button" disabled={!serviceAvailable} onClick={handleSubmitClick}>
                    {
                        serviceAvailable? 
                            t('Close') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => {

    return ({
        modalData: state.modal.positionClose,
        oneClickTrading: state.app.oneClickTrading
    })
}

export default connect(mapStateToProps)(PositionCloseModal);