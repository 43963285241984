import React from 'react';
import { connect } from 'react-redux';
import { DataTableTabs as Tabs } from './constants';
import { RootState } from 'store/types';
import { AppBottomFieldHeight } from 'store/slices/app-slice/types';
// Tables
import appState from 'store/slices/app-slice/state';
import useTranslation from 'modules/localization';
import bottomFieldResizerHelper from 'components/field-resizers/bottom-field-resizer-helper';
import OrdersAndPositionsTable from '../orders-and-positions-table';
import HistoryTable from '../history-table';
import ExposureToolTable from '../exposure-tool-table';

interface Props {
    bottomFieldHeight: AppBottomFieldHeight,
}

const DataTables = (props: Props) => {
    const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.ORDERS_AND_POSITIONS);
    const { t } = useTranslation();

    const getTabClassName = (tab: Tabs) => {
        return activeTab === tab? 'active' : undefined;
    }

    const resetBottomFieldHeight = () => {
        bottomFieldResizerHelper.setFieldHeight(30);
    }

    const setBottomFieldHeightAsDefault = () => {        
        bottomFieldResizerHelper.setFieldHeight(appState.bottomFieldHeight);
    }

    const bottomFieldCondition = props.bottomFieldHeight > 30;
    const changeTableHeight = () => {
        if (bottomFieldCondition) {
            resetBottomFieldHeight();
        } else {
            setBottomFieldHeightAsDefault();
        }
    }

     
    return (
        <div className="datatables">
            <div className="header">
                <div className="tabs">
                    <button className={getTabClassName(Tabs.ORDERS_AND_POSITIONS)} onClick={() => setActiveTab(Tabs.ORDERS_AND_POSITIONS)}>{t('Trade')}</button>
                    <button className={getTabClassName(Tabs.EXPOSURE_TRADING_TOOLS)} onClick={() => setActiveTab(Tabs.EXPOSURE_TRADING_TOOLS)}>{t('ExposureTradingTools')}</button>
                    <button className={getTabClassName(Tabs.HISTORY)} onClick={() => setActiveTab(Tabs.HISTORY)}>{t('History')}</button>
                </div>
                <div className="controls">
                    <button className="hide-table" onClick={changeTableHeight}><i className="icofont-eye-blocked"></i> <span>{bottomFieldCondition? t('HideTable') : t('ResetTable')}</span></button>
                </div>
            </div>
            <div className="content">
                {activeTab === Tabs.ORDERS_AND_POSITIONS && <OrdersAndPositionsTable />}
                {activeTab === Tabs.EXPOSURE_TRADING_TOOLS && <ExposureToolTable />}
                {activeTab === Tabs.HISTORY && <HistoryTable />}
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    bottomFieldHeight: state.app.bottomFieldHeight,    
})

export default connect(mapStateToProps)(DataTables);