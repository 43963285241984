import Api from "api"

export const exposureToolSymbolType = {
    NONE: { TEXT: 'SelectSymbol', VALUE: null },
    ALL: { TEXT: 'AllSymbols', VALUE: 'ALL' }
}

export const exposureToolCloseType = {
    NONE: { TEXT: 'SelectCloseType', VALUE: null },
    CLOSE_ALL_POSITIONS: { TEXT: 'CloseAll', VALUE: Api.EnPositionCloseType.CloseAll },
    CLOSE_ALL_BUY_POSITIONS: { TEXT: 'CloseAllBuy', VALUE: Api.EnPositionCloseType.CloseAllBuy },
    CLOSE_ALL_SELL_POSITIONS: { TEXT: 'CloseAllSell', VALUE: Api.EnPositionCloseType.CloseAllSell },
    CLOSE_ALL_POSITIVE_POSITIONS: { TEXT: 'CloseAllPositiveProfit', VALUE: Api.EnPositionCloseType.CloseAllPositiveProfit },
    CLOSE_ALL_NEGATIVE_POSITIONS: { TEXT: 'CloseAllNegativeProfit', VALUE: Api.EnPositionCloseType.CloseAllNegativeProfit },
}

export const exposureToolCancelType = {
    NONE: { TEXT: 'SelectCancelType', VALUE: null },
    CANCEL_ALL_ORDERS: { TEXT: 'CancelAll', VALUE: Api.EnOrderCancelType.CancellAll },
    CANCEL_ALL_BUY_ORDERS: { TEXT: 'CancelAllBuy', VALUE: Api.EnOrderCancelType.CancelAllBuy },
    CANCEL_ALL_SELL_ORDERS: { TEXT: 'CancelAllSell', VALUE: Api.EnOrderCancelType.CancelAllSell },
}
