import React, { useState } from 'react';
import { connect } from 'react-redux';
import { default as sortHelper }  from './sort-helper';
// Components
import WatchListItem from './item';
// Types
import { RootState } from 'store/types';
import { WatchListContainerProps } from './types';

const WatchListContainer = (props: WatchListContainerProps) => {
    const useSortElementState = [sortHelper.element.state, sortHelper.element.set] = React.useState<string | null>(null);
    const [filter] = props.useFilter;

    const [filteredSymbols, setFilteredSymbols] = useState<string[]>(new Array<string>());
  
    const filterSymbols = () => {
        setFilteredSymbols(props.watchList.slice()!.filter((symbol) => symbol.indexOf(filter) !== -1));
    }

    React.useEffect(() => {
        filterSymbols();
    }, [filter, props.watchList]);


    return (
        <div className="watchlist-container" 
            onMouseMove={sortHelper.handleContainerMouseMove} 
            onMouseLeave={sortHelper.handleContainerMouseLeave} 
            onMouseUp={sortHelper.handleContainerMouseUp} >
            {
                filteredSymbols!.map((symbol, index) => {     
                    const data = props.symbolData[symbol];
                    if (data === undefined) { return <></>; }
                    return <WatchListItem key={symbol} index={index} useSort={useSortElementState} {...data} />
                })
            }
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return ({
        symbolData: state.symbol.data,
        symbolList: state.symbol.group?.symbols!,
        watchList: state.symbol.watchList,
        defaultWatchListSymbols: state.symbol.defaultWatchListSymbols
    });
}

export default connect(mapStateToProps)(WatchListContainer);