import HubBase from "../_base";
import { ITradeMasterHub } from "./types";
import getEncryptedAccessToken from "shared/utils/getEncryptedAccessToken";
import storeManager from "store/manager";
import { appConfig } from "app/config";
import { PositionOrderDealData } from "../../../shared/socket/types";
import { DateTime } from "luxon";

class TradeMasterHub extends HubBase implements ITradeMasterHub {
    constructor() {
        const accountNo = storeManager.account.getAccountNo() || '';
        const accessToken = getEncryptedAccessToken();
        
        const url = new URL(appConfig.socketServiceUrl + appConfig.hubs.trademaster);
        url.searchParams.append('login', accountNo.toString());
        url.searchParams.append('enc_auth_token', accessToken);

        // config.hubs.trademaster.url + '?login=' + accountNo.toString() + '&enc_auth_token=' + encodeURIComponent(accessToken)
        super(url.href);
    }

    // <Lifecycle>
    /** Description: Starts the TradeMasterHub connection. */
    start = () => {
        this.onConnectionDefine = () => {
            // Handler registrations
            this.connection?.on('receiveTradingAccountInfo', this.receiveTradingAccountInfo);
            this.connection?.on('receiveConSymbol', this.receiveConSymbol);
            this.connection?.on('receiveGroupConfig', this.receiveGroupConfig);
        }

        return {
            then: (handleConnectionStart: Function) => {
                this.onConnectionStart = handleConnectionStart;
                this.connect();
            }
        };
    }


    receiveGroupConfig() {
                // todo : implemente edilecek
    }

    receiveConSymbol() {
        // todo : implemente edilecek
    }

    receiveTradingAccountInfo(response: PositionOrderDealData) {

        //const tradingViewTimeZone = storeManager.account.getTradingViewTimeZone();

        response.positions.forEach((position) => {
            position.timeCreate = DateTime.fromISO(position.timeCreate.toString(), { zone: "utc" });
        })

        response.orders.forEach((order) => {
            order.timeSetup = DateTime.fromISO(order.timeSetup.toString(), { zone: "utc" });
        })

    
        storeManager.orderPositionDeal.setPositions(response.positions);
        storeManager.orderPositionDeal.setOrders(response.orders);

        storeManager.account.setTradingStatus({
            balance: response.balance,
            credit: response.credit,
            equity: response.equity,
            margin: response.margin,
            marginFree: response.marginFree,
            marginLevel: response.marginLevel,
            volume: response.volume,
            profit: response.equity - response.balance,
        });
    }


    /** Description: Updates the TradeMasterHub subscription list.*/
    update(subscriptionList: Array<string>) {
        if (subscriptionList !== this.subscriptions) {
            this.unsubscribe();
            this.subscriptions = subscriptionList;
            this.subscribe();
        }
    }

    /** Description: Closes the TradeMasterHub connection. */
    close() {
        this.disconnect();
    }
    // </Lifecycle>
}

export default TradeMasterHub;