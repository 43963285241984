import Api, { ApiTypes } from "api";
import { toast } from 'react-toastify';
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import Dependencies from "shared/app/dependencies";
import OrderValidator from "./validator";
import { thunkService } from "services";
import { ApiResponse } from "../../shared/api/constants";
import getOrderType from "../../shared/utils/getOrderType";

class OrderProvider {
    private readonly _storeManager;
    private readonly _orderService;
    private readonly _orderValidator;
    private readonly _dispatch;
    public readonly ROW_TRANSACTION_ANIMATION_MS = 3000;

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
        this._dispatch = dependencies.dispatch;
        
        this._orderService = new Api.OrderServiceProxy();
        this._orderValidator = new OrderValidator();
    }

    /** Description: Creates an order. */
    public async create() {
        return new Promise(async (resolve, reject) => {

            const accountNo = getAccountNo();
            const platformCode = getPlatformCode();
            const data = this._storeManager.modal.getOrderCreate().data;

            const inputDto: ApiTypes.CreateOrderInputDto = new Api.CreateOrderInputDto({
                accountNo: accountNo,
                platformCode: platformCode,
                type: getOrderType(data.orderType, data.opType)!,
                price: data.price,
                lots: data.amount,
                stopLoss: data.stopLoss,
                takeProfit: data.takeProfit,
                symbol: data.symbol,
                expireDate: undefined,
                comment: ""
            })

            const isValid = this._orderValidator.validateCreate(inputDto);
            if (isValid) {
                const response: ApiResponse<ApiTypes.CreateOrderOutputDto> = await this._orderService.create(inputDto) as any;
                if (response.success) {

                } else {
                    toast(response.message);
                }

                resolve(response);
            } else {
                reject();
            }

        })
    }

    /** Description: Edits an order. */
    public async modify() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const data = this._storeManager.modal.getOrderModify().data;
        
        const inputDto = new Api.ModifyOrderInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            ticket: data.orderId,
            type: data.orderSide,
            price: data.price,
            stopLoss: data.stopLoss,
            takeProfit: data.takeProfit,
            comment: data.comment
        });

        const response: ApiResponse<ApiTypes.ModifyOrderOutputDto> = await this._orderService.modify(inputDto) as any;
        if (response.success) {
            const order = response.data.order;

           // Row transaction animation
            setTimeout(() => {
                const orderRow = document.getElementById(`order-${order.order}`);
                if (orderRow) {
                    orderRow.classList.add('modify');
                }

                setTimeout(() => {
                    if (orderRow) {
                        orderRow.classList.remove('modify');
                    }

                }, this.ROW_TRANSACTION_ANIMATION_MS);
                
            }, 0); // TODO: should be outside of setTimeout method 


        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    /** Description: Closes an order. */
    public async close() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        //const data = this._storeManager.modal.getOrderClose().data;
        
        const inputDto = new Api.OrderRemoveInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            ticket: 0,//data.orderId,
            symbol: "",//data.symbol,
            comment: ""
        })

        const response: ApiResponse<ApiTypes.OrderRemoveOutputDto> = await this._orderService.remove(inputDto) as any;
        if (response.success) {
            const order = response.data.order;
            const positions = this._storeManager.orderPositionDeal.getPositions();
            this._storeManager.orderPositionDeal.setPosition([...positions].filter((position) => position.position !== order.order));

        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    /** Description: Closes all of orders. */
    public async closeAll(input: any) {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const inputDto = new Api.CloseAllOrdersInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            closeSymbolType: input.closeSymbolType,
            operationType: input.operationType,
        })

        const response: ApiResponse<ApiTypes.CloseAllOrdersOutputDto> = await this._orderService.closeAllOrders(inputDto) as any;
        if (response.success && response.data.succesOrders?.length) {
            const succespositions = response.data.succesOrders;
            const positions = this._storeManager.orderPositionDeal.getPositions();
            this._storeManager.orderPositionDeal.setOpen([...positions].filter(position => !succespositions?.some((succesposition) => succesposition.order === position.position)));
        } else {
            toast('Multiple close failed.');
        }
    }

    /** Description: Deletes an order. */
    public async delete(): Promise<void> {

        return new Promise(async(resolve) => {
            const accountNo = getAccountNo();
            const platformCode = getPlatformCode();
            const data = this._storeManager.modal.getOrderDelete().data;

            const inputDto = new Api.OrderRemoveInputDto({
                accountNo: accountNo,
                platformCode: platformCode,
                ticket: data.order,
                comment: data.comment,
                symbol: data.symbol
            })

            const response: ApiResponse<ApiTypes.OrderRemoveOutputDto> = await this._orderService.remove(inputDto) as any;

            if (response.success) {
                const order = response.data.order;
                const orders = this._storeManager.orderPositionDeal.getOrders();
                const currentOrders = [...orders].filter((_order) => _order.order !== order.order);

                this._storeManager.orderPositionDeal.setOrders(currentOrders);
                resolve();

            } else {
                toast(response.message);
            }
        });
    }

    public getHistory(startDate: string, endDate: string) {
        return this._dispatch(thunkService.order.getOrderHistory({ startDate, endDate }));
    }
}

export default OrderProvider;