import React, { useEffect } from 'react';
import IMAGES from 'assets/images';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
import useTranslation from 'modules/localization';
// Components
import Modal from 'components/modal';
// Types
import { RootState } from 'store/types';
import { ModalPositionModify } from 'store/slices/modal-slice/types';
import getDecimalCount from 'shared/utils/getDecimalCount';
import { positionService } from '../../../services';
import { EnPositionAction } from '../../../api/service-proxies';
import { PositionActions } from '../../../shared/trade/enums';
import { SymbolFeedData } from '../../../shared/symbol/types';

interface Props {
    modalData: ModalPositionModify, symbolfeed: SymbolFeedData
}

const PositionModifyModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    const positionData = props.modalData.data;
    const { position, symbol, type, priceOpen, priceTP, priceSL, digits } = positionData;
    
    const changePositionData = (property: string, value: any) => {
        storeManager.modal.setPositionModify({
            ...props.modalData, 
            data: { 
                ...positionData,
                [property]: value 
            }
        })
    }

    useEffect(() => {


    }, [props.symbolfeed])

    const handleClose = () => storeManager.modal.setPositionModify({ visibility: false, data: null })

    const changeTakeProfit = (value: string) => getDecimalCount(value) <= digits ? changePositionData('priceTP', value.replace(',', '.')) : null
    const changeStopLoss = (value: string) => getDecimalCount(value) <= digits ? changePositionData('priceSL', value.replace(',', '.')) : null

    const handleSubmitClick = () => {
        if (!serviceAvailable) return;
        
        setServiceAvailable(false);
        positionService.provider.modify()
            .then(() => handleClose())
            .finally(() => setServiceAvailable(true));
    }
    
    return (
        <Modal title={t('ModifyPosition')} id="position-modify-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                <div className="description">
                    #{position} {PositionActions[type]} {priceOpen}
                </div>
                <div className="price" style={{
                    textAlign: 'center',
                    lineHeight:3.0
                }}>
                    <div>{props.symbolfeed.bid.toFixed(props.symbolfeed.digits)} / {props.symbolfeed.ask.toFixed(props.symbolfeed.digits)}</div>
                </div>    
                <div className="takeprofit-stoploss">
                    <div className={'col' + (type === EnPositionAction.POSITION_SELL) ? ' flex-end' : ''}>
                        <label htmlFor="modify-take-profit" className="take-profit-label">{t('TakeProfit')}</label>
                        <input id="modify-take-profit" type="text" value={priceTP} onChange={(e) => changeTakeProfit(e.target.value)} />
                    </div>
                    <div className="col mid-col">
                        <img src={type === EnPositionAction.POSITION_SELL ? IMAGES.ICON.TPSL_SELL : IMAGES.ICON.TPSL_BUY} alt="" />
                    </div>
                    <div className={'col' + (type === EnPositionAction.POSITION_BUY) ? ' flex-end' : ''}>
                        <label htmlFor="modify-stop-loss" className="stop-loss-label">{t('StopLoss')}</label>
                        <input id="modify-stop-loss" type="text" value={priceSL} onChange={(e) => changeStopLoss(e.target.value)} />
                    </div>
                </div>        
                <button type="submit" className="submit-button" disabled={!serviceAvailable} onClick={handleSubmitClick}>
                    {
                        serviceAvailable? 
                            t('Modify') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => {

    return ({
        modalData: state.modal.positionModify,
        symbolfeed: state.symbol.data[state.modal.positionModify.data.symbol]
    })
}

export default connect(mapStateToProps)(PositionModifyModal);