import React from 'react';
import { connect } from 'react-redux';
// Components
import AccordionListItem from '../src/item';
// Types
import { RootState } from 'store/types';
import { Nullable } from 'shared/global/types';
import { SupportResistanceValue } from 'shared/indicator/interfaces';
import { IndicatorSupportResistance } from 'store/slices/indicator-slice/types';
import useTranslation from 'modules/localization';
import supportResistanceHelper from 'helpers/support-resistance-helper';
import { SymbolFeedData } from '../../../shared/symbol/types';

interface Props {
    srData: IndicatorSupportResistance,
    symbolData: { [key: string]: SymbolFeedData },
    analysis: string
}
const SupportResistanceItem = (props: Props) => {
    const [srValue, setSrValue] = React.useState<Nullable<SupportResistanceValue>>(null);
    const { t } = useTranslation(); 

    const symbol = props.analysis;
    const srData = props.srData.hasOwnProperty(symbol)? props.srData[symbol] : null;
    const data = props.symbolData;
    const hasSymbolData = data.hasOwnProperty(symbol);
    const symbolData = hasSymbolData? data[symbol] : null;

    const { ask, bid, digits } = symbolData || {};
    const price = ask?.toFixed(digits);
    
    React.useEffect(() => {
        const supportResistance = supportResistanceHelper.getValues({ srValues: srData, ask, bid });
        setSrValue(supportResistance);

    }, [srData, ask, bid])

    const r2Price = srValue?.top2Price? parseFloat(srValue.top2Price).toFixed(digits) : '...';
    const r2Value = srValue?.top2Value? parseFloat(srValue.top2Value).toFixed(1) : '...';
    const r1Price = srValue?.top1Price? parseFloat(srValue.top1Price).toFixed(digits) : '...';
    const r1Value = srValue?.top1Value? parseFloat(srValue.top1Value).toFixed(1) : '...';
    const s1Price = srValue?.bottom1Price? parseFloat(srValue.bottom1Price).toFixed(digits) : '...';
    const s1Value = srValue?.bottom1Value? parseFloat(srValue.bottom1Value).toFixed(1) : '...';
    const s2Price = srValue?.bottom2Price? parseFloat(srValue.bottom2Price).toFixed(digits) : '...';
    const s2Value = srValue?.bottom2Value? parseFloat(srValue.bottom2Value).toFixed(1) : '...';

    return (
        <AccordionListItem id={'support-resistance'} title={t('SupportResistanceTitle')} icon="icofont-sort">
            <table className="support-resistance-table">
                <tbody>
                    <tr className="center-t resistance-color">
                        <td colSpan={2}>{r2Value}</td>
                        <td colSpan={4}>{t('Resistance2')}</td>
                        <td colSpan={2}>{r2Price}</td>
                    </tr>
                    <tr className="center-t resistance-color">
                        <td colSpan={2}>{r1Value}</td>
                        <td colSpan={4}>{t('Resistance1')}</td>
                        <td colSpan={2}>{r1Price}</td>
                    </tr>
                    <tr className="center-t price-color">
                        <td colSpan={8}>
                            {price}
                        </td>
                    </tr>
                    <tr className="center-t support-color">
                        <td colSpan={2}>{s1Value}</td>
                        <td colSpan={4}>{t('Support1')}</td>
                        <td colSpan={2}>{s1Price}</td>
                    </tr>
                    <tr className="center-t support-color">
                        <td colSpan={2}>{s2Value}</td>
                        <td colSpan={4}>{t('Support2')}</td>
                        <td colSpan={2}>{s2Price}</td>
                    </tr>
                </tbody>
            </table>
        </AccordionListItem>
    )
};

const mapStateToProps = (state: RootState) => ({
    srData: state.indicator.supportResistance,
    symbolData: state.symbol.data,
    analysis: state.symbol.analysis
});

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
    // rerender problem (has leaks)
    if (prevProps.srData === nextProps.srData && prevProps.analysis === nextProps.analysis 
        && prevProps.symbolData?.[nextProps.analysis] === nextProps.symbolData?.[nextProps.analysis]) {
            return true;
    }
    
    return false;
}

const memoizedSupportResistanceItem = React.memo(SupportResistanceItem, propsAreEqual);

export default connect(mapStateToProps)(memoizedSupportResistanceItem);