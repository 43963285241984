import { EntityStatus } from "./enums";
import { Nullable, StoreMiddlewareEntity as Entity } from "./types";

/** Description: Its main entity to apply the middleware. */
export class StoreMiddlewareEntity<T> {
    public status: Nullable<EntityStatus>;
    public updateTime: Nullable<EpochTimeStamp>;
    public data: T;
    public message: Nullable<string>;

    constructor(entity: Entity<T>) {
        this.status = entity.status;
        this.updateTime = entity.updateTime;
        this.data = entity.data;
        this.message = entity.message;
    }
}