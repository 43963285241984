import { ModalState } from "./types";

const modalState: ModalState = {
    // Alarm
    alarmList: { visibility: false, data: null },
    alarmCreate: { visibility: false, data: null },
    alarmModify: { visibility: false, data: null },
    alarmDelete: { visibility: false, data: null },
    alarmNotice: { visibility: false, data: null },
    // Symbol
    symbolList: {visibility: false, data: null },
    // Order
    orderCreate: { visibility: false, data: null },
    orderModify: { visibility: false, data: null },
    orderDelete: { visibility: false, data: null },

    positionClose: { visibility: false, data: null },
    positionModify: { visibility: false, data: null },


    // Account
    settings: { visibility: false, data: null },
    password: { visibility: false, data: null },
    tradingAccounts: { visibility: false, data: null },
    // Automatic System
    automaticSystem: { visibility: false, data: null },
    automaticSystemForwarding: { visibility: false, data: null },
    oneClickTradingDisclaimer: { visibility: false, data: null },
    // Chart
    economicCalendar: { visibility: false, data: null },
}

export default modalState;