import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { AccountState } from "./types";

const accountState: AccountState = {
    accountNo: null,
    user: null,
    loginInformations: new StoreMiddlewareEntity({ data: { defaultWatchListSymbols: new Array<string>(), tenantSummary: null }, status: EntityStatus.IDLE, updateTime: null, message:null }),
    tradingStatus: null,
    tradingViewTimeZone: "",
    tradingPlatformAvailable: false
}

export default accountState;