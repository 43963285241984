export enum Op {
    Buy,
    Sell,
    BuyLimit,
    SellLimit,
    BuyStop,
    SellStop,
    Balance,
    Credit
}

export const Cmd: any = {
    0: "Buy",
    1: "Sell",
    2: "Buy Limit",
    3: "Sell Limit",
    4: "Buy Stop",
    5: "Sell Stop"
}

export const EntryType: any = {
    0: "In",
    1: "Out",
    2: "In/Out",
    3: "Out By"
}

export const DealActions:any = {
    0: "Buy",
    1: "Sell",
    2: "Balance",
    3: "Credit",
    4: "Charge",
    5: "Correction",
    6: "Bonus",
    7: "Commission",
    8: "Commission Daily",
    9: "Commission Monthly",
    10: "Agent Daily",
    11: "Agent Monthly",
    12: "Interestrate",
    13: "Buy Canceled",
    14: "Sell Canceled",
    15: "Dividend",
    16: "Dividend Franked",
    17: "Tax",
    18: "Agent",
    19: "So Compensation",
    20: "So Compensation Credit"
}

export const OrderTypes: any = {
    0:"Buy",
    1:"Sell",
    2:"Buy Limit",
    3:"Sell Limit",
    4:"Buy Stop",
    5:"Sell Stop",
    6:"Buy Stop Limit",
    7:"Sell Stop Limit",
    8:"Close By",
}


export const PositionActions: any = {
    0: "Buy",
    1: "Sell",
}