// Helpers
import StoreManagerHelper from "./helpers/manager-helper";
// Store context
import storeContext from "./context";
// Slices
import appSlice from "./slices/app-slice";
import modalSlice from "./slices/modal-slice";
import orderPositionDealSlice from "./slices/order-position-deal-slice";
import chartSlice from "./slices/chart-slice";
import symbolSlice from "./slices/symbol-slice";
import accountSlice from "./slices/account-slice";
import indicatorSlice from "./slices/indicator-slice";
import alarmSlice from "./slices/alarm-slice";
// Types
import { AppState } from "./slices/app-slice/types";
import { ModalState } from "./slices/modal-slice/types";
import { OrderPositionDealState } from "./slices/order-position-deal-slice/types";
import { ChartState } from "./slices/chart-slice/types";
import { SymbolState } from "./slices/symbol-slice/types";
import { AccountState } from "./slices/account-slice/types";
import { IndicatorState } from "./slices/indicator-slice/types";
import { AlarmState } from "./slices/alarm-slice/types";

class StoreManager {
    public readonly app;
    public readonly modal;
    public readonly orderPositionDeal;
    public readonly chart;
    public readonly symbol;
    public readonly account;
    public readonly indicator;
    public readonly alarm;

    constructor() {
        const storeManagerHelper = new StoreManagerHelper(storeContext);
        
        type AppActions = typeof appSlice.actions;
        type ModalActions = typeof modalSlice.actions;
        type OrderPositionDealActions = typeof orderPositionDealSlice.actions;
        type ChartActions = typeof chartSlice.actions;
        type SymbolActions = typeof symbolSlice.actions;
        type AccountActions = typeof accountSlice.actions;
        type IndicatorActions = typeof indicatorSlice.actions;
        type AlarmActions = typeof alarmSlice.actions;

        this.app = storeManagerHelper.createManager<AppState, AppActions>(appSlice);
        this.modal = storeManagerHelper.createManager<ModalState, ModalActions>(modalSlice);
        this.orderPositionDeal = storeManagerHelper.createManager<OrderPositionDealState, OrderPositionDealActions>(orderPositionDealSlice);
        this.chart = storeManagerHelper.createManager<ChartState, ChartActions>(chartSlice);
        this.symbol = storeManagerHelper.createManager<SymbolState, SymbolActions>(symbolSlice);
        this.account = storeManagerHelper.createManager<AccountState, AccountActions>(accountSlice);
        this.indicator = storeManagerHelper.createManager<IndicatorState, IndicatorActions>(indicatorSlice);
        this.alarm = storeManagerHelper.createManager<AlarmState, AlarmActions>(alarmSlice);
    }
}

const storeManager = new StoreManager();
export default storeManager;