import { createSlice } from "@reduxjs/toolkit";
import symbolThunk from "./middleware";
// State
import symbolState from "./state";
// Types
import { SymbolReducers } from "./types";
import { SymbolFeedData } from '../../../shared/symbol/types';

const symbolSlice = createSlice({
    name: 'symbol',
    initialState: symbolState,
    reducers: {
        updateData(state, action) {
            state.data = {
                ...state.data,
                [action.payload.symbol]: action.payload
            };
        },
        setWatchList(state, action) {

            let data: { [key: string]: SymbolFeedData } = {};

            (action.payload as string[]).forEach(_symbol => {
                //const symbol = (state.group.symbols!).find(f => f.symbol === _symbol);
                const symbol = state.data[_symbol];

                if (symbol !== undefined) {
                    data[symbol.symbol!] = { symbol: symbol.symbol, bid: symbol.bid, ask: symbol.bid, digits: symbol.digits, point: symbol.point, dif: symbol.spread, high: symbol.high, low: symbol.low } as SymbolFeedData;
                }
            });

            state.data = data;
            state.watchList = action.payload;
        },
        setAnalysis(state, action) {
            state.analysis = action.payload;
        },
        setGroup(state, action) {
            state.group = action.payload
        }
    } as SymbolReducers,
    extraReducers(builder) {
        symbolThunk.addCases(builder);
    },
})

export default symbolSlice;