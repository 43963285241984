import React from 'react';
import IMAGES from 'assets/images';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
import useTranslation from 'modules/localization';
// Components
import Modal from 'components/modal';
// Types
import { RootState } from 'store/types';
import { ModalOrderModify } from 'store/slices/modal-slice/types';
import { orderService } from 'services';
import getDecimalCount from 'shared/utils/getDecimalCount';
import { EnOrderType, OrderDto } from '../../../api/service-proxies';
import { OrderTypes } from '../../../shared/trade/enums';

interface Props {
    modalData: ModalOrderModify,
}

const OrderModifyModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    const orderData = props.modalData.data;
    const { order, symbol, type, priceOrder, priceTP, priceSL, digits } = orderData;
    const frozenPrice = false;
    
    const changeOrderData = (property: string, value: any) => {
        storeManager.modal.setOrderModify({
            ...props.modalData, 
            data: { 
                ...orderData,
                [property]: value 
            }
        })
    }

    const handleClose = () => storeManager.modal.setOrderModify({ visibility: false, data: null })

    const changeTakeProfit = (value: string) => getDecimalCount(value) <= digits ? changeOrderData('priceTP', value.replace(',', '.')) : null
    const changeStopLoss = (value: string) => getDecimalCount(value) <= digits ? changeOrderData('priceSL', value.replace(',', '.')) : null

    const handleSubmitClick = () => {
        if (!serviceAvailable) return;
        
        setServiceAvailable(false);
        orderService.provider.modify()
            .then(() => handleClose())
            .finally(() => setServiceAvailable(true));
    }
    
    return (
        <Modal title={t('ModifyOrder')} id="order-modify-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                <div className="description">
                    #{order} {OrderTypes[type]}
                </div>
                <div className="price">
                    <label htmlFor="modify-price">{t('Price')}</label>
                    <input id="modify-price" type="number" placeholder={priceOrder.toString()} value={!frozenPrice ? priceOrder : undefined} readOnly={frozenPrice} onChange={!frozenPrice ? ((e) => changeOrderData('priceOrder', e.currentTarget.value)) : undefined} />
                </div>    
                <div className="takeprofit-stoploss">
                    <div className={'col' + (type === EnOrderType.OP_SELL ? ' flex-end' : '')}>
                        <label htmlFor="modify-take-profit" className="take-profit-label">{t('TakeProfit')}</label>
                        <input id="modify-take-profit" type="text" value={priceTP} onChange={(e) => changeTakeProfit(e.target.value)} />
                    </div>
                    <div className="col mid-col">
                        <img src={type === EnOrderType.OP_SELL? IMAGES.ICON.TPSL_SELL : IMAGES.ICON.TPSL_BUY} alt="" />
                    </div>
                    <div className={'col' + (type === EnOrderType.OP_BUY ? ' flex-end' : '')}>
                        <label htmlFor="modify-stop-loss" className="stop-loss-label">{t('StopLoss')}</label>
                        <input id="modify-stop-loss" type="text" value={priceSL} onChange={(e) => changeStopLoss(e.target.value)} />
                    </div>
                </div>        
                <button type="submit" className="submit-button" disabled={!serviceAvailable} onClick={handleSubmitClick}>
                    {
                        serviceAvailable? 
                            t('Modify') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    modalData: state.modal.orderModify
})

export default connect(mapStateToProps)(OrderModifyModal);