import React from 'react';

interface Props {
    price: number,
    digits: number,
}

const SymbolPrice = (props: Props) => {
    if (props.price) {
        const priceString = props.price?.toFixed(props.digits);
        const digitSplit = priceString?.split('.');
        const digitCount = digitSplit[1]?.length;

        if (digitCount) {
            const isOddNumber = digitCount % 2 !== 0;
            let normalText = '';
            let bigText = '';
            let smallText = '';
    
            // if digit count is odd number
            const lastCharacterIndex = priceString.length - 1;
            if (isOddNumber) {
                if (digitCount >= 3) {
                    // NNN.BBS
                    smallText = priceString[lastCharacterIndex];
                    bigText = priceString.substring(lastCharacterIndex - 2, lastCharacterIndex);
                    normalText = priceString.substring(0, lastCharacterIndex - 2);
                    
                } else {
                    // NNN.B
                    // if digit count is one
                    bigText = priceString[lastCharacterIndex];
                    normalText = priceString.substring(0, lastCharacterIndex);
                }
    
                
            } else {
                // if digit count is even number
                if (digitCount === 0) {
                    // NNN.
                    normalText = priceString;
    
                } else if (digitCount >= 2) {
                    // NN.NNBB or NN.BB
                    bigText = priceString.substring(lastCharacterIndex - 1, priceString.length);
                    normalText = priceString.substring(0, lastCharacterIndex - 1);
                } 
            }
    
            // if the last character of normal text is dot, it will be deleted
            // normalText = rightDotTrim(normalText);

            return (
                <React.Fragment>
                    <div className="standard">{normalText}</div>
                    <div className="larger">{bigText}</div>
                    <sup className="smaller">{smallText}</sup>  
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            {props.price.toFixed(props.digits)}
        </React.Fragment>
    );
}

export default SymbolPrice;