import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
// Types
import { RootState } from 'store/types';
import storeManager from 'store/manager';
import useTranslation from 'modules/localization';
import { chartService, orderService } from 'services';
import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';
import { OrderDto } from '../../../api/service-proxies';
import { OrderTypes } from '../../../shared/trade/enums';

interface Props {
    data: any,
    sortedColumn: SortedColumn,
    tradingViewTimeZone: string
}

const OrdersTable = (props: Props) => {
    const { t } = useTranslation();

    const openModifyOrderModal = (data: OrderDto) => {

        storeManager.modal.setOrderModify({
            visibility: true,
            data: {
                order: data.order,
                symbol: data.symbol,
                type: data.type,
                digits: data.digits,
                priceOrder: data.priceOrder.toFixed(data.digits),
                priceSL: data.priceSL.toFixed(data.digits),
                priceTP: data.priceTP.toFixed(data.digits),
            }
        });
    }
    
    const openDeleteOrderModal = (data: OrderDto) => {

        storeManager.modal.setOrderDelete({
            visibility: true, data: {
                order: data.order,
                symbol: data.symbol,
                type: data.type,
            }
        })

    };

    const handleCloseMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        const rowElement = event.currentTarget.parentElement?.parentElement?.parentElement; 
        if (rowElement) {
            rowElement.classList.add('close');
            
            setTimeout(() => {
                if (rowElement) {
                    rowElement.classList.remove('close');
                }
    
            }, orderService.provider.ROW_TRANSACTION_ANIMATION_MS / 2);

        }
    }

    const data = tableColumnSortHelper.getSortedData({ sortStateGetter: props.sortedColumn, data: props.data.orders });

    return (
        <React.Fragment>
            <tr className="table-title">
                <td colSpan={12}><b>{t('Orders')}</b></td>
            </tr>
            {
                data?.map((data: OrderDto) => {
                    const openPrice = data.priceOrder.toFixed(data.digits);
                    const stopLoss = data.priceSL === 0 ? "" : data.priceSL.toFixed(data.digits);
                    const takeProfit = data.priceTP === 0 ? "" : data.priceTP.toFixed(data.digits);
                    const closePrice = data.priceCurrent.toFixed(data.digits);
                    const volume = data.volumeCurrent.toFixed(2);

                    return (
                        <tr key={data.order} id={`order-${data.order}`}>
                            <td>#{data.order}</td>
                            <td>{data.timeSetup.setZone(props.tradingViewTimeZone).toFormat('yyyy.LL.dd HH:mm:ss')}</td>
                            <td>{OrderTypes[data.type]}</td>
                            <td>{volume}</td>
                            <td className="chart-button" onClick={() => chartService.changeSelectedTab(data.symbol!)}>
                                {data.symbol}
                                <i className="icofont-chart-line-alt"></i>
                            </td>
                            <td>{openPrice}</td>
                            <td>{stopLoss}</td>
                            <td>{takeProfit}</td>
                            <td>{closePrice}</td>
                            <td></td>
                            <td></td>
                            <td>
                                <div className="options">
                                    <button className="modify-button" onClick={() => openModifyOrderModal(data)}>
                                        {t('Modify')}
                                        <i className="icofont-edit"></i>
                                    </button>
                                    <button className="delete-button" onClick={() => openDeleteOrderModal(data)} onMouseDown={handleCloseMouseDown}>
                                        {t('Delete')}
                                        <i className="icofont-close-squared-alt"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => {
    return ({
        data: state.orderPositionDeal,
        tradingViewTimeZone: state.account.tradingViewTimeZone
    })
}

export default connect(mapStateToProps)(OrdersTable);