import storageManager from "modules/storage-manager";
import React from "react";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import storeManager from "store/manager";

class WatchlistSortHelper {

    public element: { state: string | null, set: React.Dispatch<React.SetStateAction<string | null>> } = { state: null, set: () => null };
    
    private _sortTimers: NodeJS.Timer[] = [];

    private readonly TOP_SENSIBILITY = 200;
    private readonly BOTTOM_SENSIBILITY = 200;
    private readonly INTERVAL_MS = 400;
    
    public handleContainerMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
        const target: any = event.target;
        if (target?.className === 'watchlist-container') {
            this.element.set(null);
        }
    }

    public handleContainerMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        this.element.set(null);
        this.clearAllSortIntervals();
    }
    
    public handleContainerMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!this.element.state) 
            return;
        
        const topArea = event.clientY - event.currentTarget.offsetTop;
        const bottomArea = (event.currentTarget.offsetTop + event.currentTarget.clientHeight) - event.clientY;

        const isInTopArea = topArea <= this.TOP_SENSIBILITY;
        const isInBottomArea = bottomArea <= this.BOTTOM_SENSIBILITY;


        this.clearAllSortIntervals();
        if (isInTopArea) {
            this.addSortTimer(-300, event);

        } else if (isInBottomArea) {
            this.addSortTimer(300, event);

        } 
    }

    public handleItemMouseDown = (sortElementKey: string) => {
        this.element.set(sortElementKey);
    }

    public handleItemMouseEnter = (sortElementKey: string) => {
        const sortElement = this.element.state;
        if (!sortElement) 
            return;
    
        let symbolList = storeManager.symbol.getWatchList().slice();
        const fromIndex = symbolList.findIndex(f => f === sortElement);
        let toIndex = symbolList.findIndex(f => f === sortElementKey);

        const fromElement = symbolList.splice(fromIndex,1);

        let firstHalf: string[] = symbolList.splice(0, toIndex);

        symbolList = [
            ...firstHalf,
            ...fromElement,
            ...symbolList
        ];


        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const storageWatchList = storageManager.preferences.get()?.watchList || {};
        storeManager.symbol.setWatchList(symbolList);
        storageManager.preferences.update({ 
            watchList:  {
                ...storageWatchList,
                [platformCode]: {
                    ...storageWatchList[platformCode],
                    [accountNo]: symbolList
                }
            }
        });
    }

    public handleItemMouseUp = () => {
        this.element.set(null);
        this.clearAllSortIntervals();
    }

    private addSortTimer = (scrollValue: number, event: React.MouseEvent<HTMLDivElement>) => {
        let counter = 1; 
        const currentTarget = event.currentTarget;

        const sortTimer = setInterval(function() {
            let topValue = currentTarget.scrollTop + (scrollValue * counter);

            if (topValue < 0) topValue = 0;
            if (topValue > currentTarget.scrollHeight) topValue = currentTarget.scrollHeight;

            currentTarget.scrollTo({ top: topValue, behavior: 'smooth' });
            counter += 1;

        }, this.INTERVAL_MS);

        this._sortTimers.push(sortTimer);
    }

    private clearAllSortIntervals = () => {
        this._sortTimers.forEach((sortTimer) => {
            clearInterval(sortTimer);
        });
    }

    public getSortStyles = (sortElementKey: string) => {
        let styles: React.CSSProperties | undefined = {
            userSelect: 'none',
        };
        
        if (this.element.state !== null && this.element.state !== sortElementKey) {
            styles.opacity = "0.15";
        }

        return styles;
    }
}

let watchlistSortHelper = new WatchlistSortHelper();
export default watchlistSortHelper;