import { createSlice } from "@reduxjs/toolkit";
// State
import modalState from "./state";
// Types
import { ModalReducers } from "./types";

const modalSlice = createSlice({
    name: 'modal',
    initialState: modalState,
    reducers: {
        // Alarm
        setAlarmList(state, action) { state.alarmList = action.payload; },
        setAlarmCreate(state, action) { state.alarmCreate = action.payload; },
        setAlarmModify(state, action) { state.alarmModify = action.payload; },
        setAlarmDelete(state, action) { state.alarmDelete = action.payload; },
        setAlarmNotice(state, action) { state.alarmNotice = action.payload; },
        // Symbol
        setSymbolList(state, action) { state.symbolList = action.payload; },
        // Order
        setOrderCreate(state, action) { state.orderCreate = action.payload; },
        setOrderModify(state, action) { state.orderModify = action.payload; },
        setOrderDelete(state, action) { state.orderDelete = action.payload; },
        //Position
        setPositionModify(state, action) { state.positionModify = action.payload; },
        setPositionClose(state, action) { state.positionClose = action.payload; },
        // Account
        setSettings(state, action) { state.settings = action.payload; },
        setPassword(state, action) { state.password = action.payload; },
        setTradingAccounts(state, action) { state.tradingAccounts = action.payload; },
        // Automatic System
        setAutomaticSystem(state, action) { state.automaticSystem = action.payload; },
        setAutomaticSystemForwarding(state, action) { state.automaticSystemForwarding = action.payload },
        setOneClickTradingDisclaimer(state, action) { state.oneClickTradingDisclaimer = action.payload },
        // Chart
        setEconomicCalendar(state, action) { state.economicCalendar = action.payload },

    } as ModalReducers
})

export default modalSlice;