import Dependencies from "shared/app/dependencies";
import PositionProvider from "./provider";

class PositionService {
    public readonly provider;
    private readonly _storeManager;
    
    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
        this.provider = new PositionProvider(dependencies); 
    }
}

export default PositionService;