import React from 'react';
import socket from 'socket';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { indicatorService, symbolService } from 'services';
import { ChartTabs } from 'store/slices/chart-slice/types';
import getPlatformCode from 'shared/utils/getPlatformCode';
import { DealDto, OrderDto, PositionDto } from '../../../api/service-proxies';
import { Nullable } from '../../../shared/global/types';

interface Props {
    watchList: string[],
    analysis: string,
    chartTabs: ChartTabs,
    positions: PositionDto[],
    orders: OrderDto[],
    deals: DealDto[],
    accountNo: Nullable<number>
}

const FeedHub = (props: Props) => {
    const [openOrders, setOpenOrders] = React.useState<string[]>([]);

    indicatorService.analysis = props.analysis;

    React.useEffect(() => {
        socket.createFeedSocket();

        return (() => {
            socket.feed.close();
        })
    }, [])

    React.useEffect(() => {
        const getOpenOrders = () => {

            const positionSymbols = props.positions?.map((order: any) => order.symbol);
            const orderSymbols = props.orders?.map((order: any) => order.symbol); 

            const symbols = [...positionSymbols, ...orderSymbols].filter((symbol, index, self) => self.indexOf(symbol) === index);

            return symbols;
        }
        
        const orders = getOpenOrders();
        if (orders.length === openOrders.length) {
            const isNotEqual = openOrders.some((order) => !orders.includes(order))
            if (isNotEqual) {
                setOpenOrders(orders);
                
            }

        } else {
            setOpenOrders(orders);
        }

    }, [openOrders, props.positions, props.orders])

    React.useEffect(() => {
        if (socket.feed ) {
        
            const getSubscriptionList = () => {
                const platformCode = getPlatformCode();

                let symbols = [...props.watchList, props.analysis, ...props.chartTabs, ...openOrders];
                symbols = symbols.filter((symbol, index, self) => self.indexOf(symbol) === index);

                let subs = symbols.map((symbol) => `${platformCode}_${symbol}`);
                const feedSubs = symbols.map((symbol) => `${platformCode}_${symbol}_Feed`);
                subs = [...subs, ...feedSubs];
        
                return subs; 
            }
            
            const changeSubscription = () => {
                const subscriptions = getSubscriptionList();
                socket.feed.update(subscriptions);
            }    
    
            const connectionState = socket.feed.getStatus();
            if (!connectionState) {
                socket.feed.start()
                .then(() => {
                    changeSubscription();
                    symbolService.storeStorageValues();
                })
            } else {
                changeSubscription();
            }

        } else {
            console.error('ERROR! Feed socket is not connected.');
        }
    }, [props.analysis, props.watchList, props.chartTabs, openOrders])
    
    return null;
}

const mapStateToProps = (state: RootState) => ({
    watchList: state.symbol.watchList,
    analysis: state.symbol.analysis,
    chartTabs: state.chart.tabs,
    positions: state.orderPositionDeal.positions,
    orders: state.orderPositionDeal.orders,
    deals: state.orderPositionDeal.deals,
    accountNo: state.account.accountNo,
})

export default connect(mapStateToProps)(FeedHub);