import { SymbolState } from "./types";
import { CIMTConGroupDto } from "../../../api/service-proxies";

const symbolState: SymbolState = {
    data: { },
    watchList: new Array<string>(),
    defaultWatchListSymbols: new Array<string>(),
    analysis: 'EURUSD',
    group: new CIMTConGroupDto()
}

export default symbolState;