import Dependencies from "shared/app/dependencies";
// Services
import AccountService from "./account";
import AlarmService from "./alarm";
import AppService from "./app";
import AuthService from "./auth";
import ChartService from "./chart";
import IndicatorService from "./indicator";
import OrderService from "./order";
import PositionService from "./position";
import SymbolService from "./symbol";
import ThunkService from "./thunk";

// Statics
export var appService: AppService;
export var accountService: AccountService;
export var authService: AuthService;
export var symbolService: SymbolService;
export var indicatorService: IndicatorService;
export var orderService: OrderService;
export var positionService: PositionService;
export var alarmService: AlarmService;
export var chartService: ChartService;

// Classics
export const thunkService = new ThunkService(); 

class Services {
    injectDependencies = (dependencies: Dependencies.Root) => {
        appService = new AppService(dependencies);
        accountService = new AccountService(dependencies);
        authService = new AuthService(dependencies);
        symbolService = new SymbolService(dependencies);
        indicatorService = new IndicatorService(dependencies);
        orderService = new OrderService(dependencies);
        positionService = new PositionService(dependencies);
        chartService = new ChartService(dependencies);
        alarmService = new AlarmService(dependencies);
    }
}

const services = new Services();
export default services;