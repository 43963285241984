import fetchApi from "api/utils/fetchApi";
import { appConfig } from "app/config";
import { AxiosRequestConfig } from "axios";

class ConfigurationProvider {
    private baseUrl: string = appConfig.apiServiceUrl;

    public getAll = (language: string | null = 'en') => fetchApi({
        method: 'GET',
        url: this.baseUrl + '/AbpUserConfiguration/GetAll?d=' + new Date().getTime(),
        headers: {
            "Accept-Language": language,
            "Abp.Tenantid": appConfig.tenantId
        }
    } as AxiosRequestConfig); 
}

const configurationProvider = new ConfigurationProvider();
export default configurationProvider;