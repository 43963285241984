import { ApiTypes } from "api";
import OrderValidatorHelper from "helpers/order-validator-helper";

class OrderValidator {
    private _helper;

    constructor() {
        this._helper = new OrderValidatorHelper();    
    }
    
    public validateCreate = (input: ApiTypes.CreateOrderInputDto) => {
        const validations = [
            this._helper.validateType(input.type),
            this._helper.validateVolume(input.lots),
            this._helper.validatePrice(input.price),
            this._helper.validateTakeProfitAndStopLoss(input.takeProfit, input.stopLoss)
        ]

        return validations.findIndex((isValid) => !isValid) === -1;
    }

    public validateModify = (input: ApiTypes.ModifyOrderInputDto) : boolean => {
        const validations = [
            this._helper.validateType(input.type),
        ]

        return validations.findIndex((isValid) => !isValid) === -1;
    }

    public validateCloseAll = () : boolean => {
        let isValid = false;

        return isValid;
    }

    public validateDelete = (input: ApiTypes.OrderRemoveInputDto): boolean => {
        const validations: Array<any> = []
        return validations.findIndex((isValid) => !isValid) === -1;
    }
}

export default OrderValidator;