import streamingProvider from "chart/src/streaming-provider";
import storageManager from "modules/storage-manager";
import { alarmService } from "services";
import Dependencies from "shared/app/dependencies";
import { SymbolFeedData, TradingStatusData } from "shared/symbol/types";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import { CIMTConGroupDto } from "../../api/service-proxies";
import { ApiTypes } from "../../api";

class SymbolService {
    public readonly MIN_COLOR_CHANGE_TIME = 500; // 0.5s
    public group: ApiTypes.CIMTConGroupDto | undefined; 

    private readonly _storeManager;
    private _prevPrices: {[prop: string]: {
        ask: number,
        bid: number,
        updatedTime: number
    }} = {};

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;

        this.updatePrevPrice = this.updatePrevPrice.bind(this);
        this.updateSGroup = this.updateSGroup.bind(this);
        this.updateSymbolFeedData = this.updateSymbolFeedData.bind(this);
        this.markUp = this.markUp.bind(this);
        this.getPrevPrices = this.getPrevPrices.bind(this);
        this.setGroup = this.setGroup.bind(this);
        this.storeStorageValues = this.storeStorageValues.bind(this);
        this.storeStorageWatchList = this.storeStorageWatchList.bind(this);
    }

    // <SocketHandler>
    /** Description: Updates the symbol feed data. (WebSocket.FeedHub) */
    public updateSymbolFeedData = async (feedData: SymbolFeedData) => {

        feedData.time = Date.now();

        // Data manupulations
        const markUpData = this.markUp(feedData);

        // save data
        this.updatePrevPrice(markUpData);

        this._storeManager.symbol.updateData(markUpData);
        
        alarmService.checkAlarmOnStream(markUpData);
        
        streamingProvider.onMessage(feedData);
    }

    /** Description: Updates the symbol config data. (WebSocket.AuctionHub) */
    public updateSGroup = (data: CIMTConGroupDto) => {
         this._storeManager.symbol.setConfig(data)
    }
    // </SocketHandler>

    private markUp = (feedData: SymbolFeedData) => {
        let markUpData = feedData;

        let spreadDiff = 0;
        const symbol = this.group?.symbols?.find(f => f.symbol === feedData.symbol);
        if (symbol !== undefined) {
            spreadDiff = symbol.spreadDiff;
        }
        

        if (spreadDiff !== undefined && feedData.point) {
            const spreadDiffHalf = Math.round(spreadDiff / 2); 
            
            markUpData.bid = markUpData.bid - spreadDiffHalf * feedData.point; 
            markUpData.ask = markUpData.ask - (spreadDiff - spreadDiffHalf) * feedData.point; 
        }

        return markUpData;
    }

    private updatePrevPrice = (feedData: SymbolFeedData) => {
        const symbol = feedData.symbol;
        const feedStoreData = this._storeManager.symbol.getData();
        const hasSymbol = feedStoreData.hasOwnProperty(symbol);
        if (hasSymbol) {
            const symbolData = feedStoreData[symbol];
            
            this._prevPrices[symbol] = {
                ask: symbolData.ask,
                bid: symbolData.bid,
                updatedTime: symbolData.time
            }

        } else {
            this._prevPrices[symbol] = {
                ask: feedData.ask,
                bid: feedData.bid,
                updatedTime: feedData.time
            }
        }

    }

    public getPrevPrices = (symbol: string) => {
        const hasSymbol = this._prevPrices.hasOwnProperty(symbol);

        if (hasSymbol) { 
            return this._prevPrices[symbol];
        }

        return null;
    }

    public setGroup = (data: CIMTConGroupDto) => {
        this._storeManager.symbol.setConfig(data)
    }




    public storeStorageValues = () => {
        this.storeStorageWatchList();
    }

    private storeStorageWatchList = () => {
        const storageValue = storageManager.preferences.get()?.watchList;

        if (storageValue) {
            const accountNo = getAccountNo();
            const platformCode = getPlatformCode();

            const storageWatchList = storageValue?.[platformCode]?.[accountNo];

            if (Array.isArray(storageWatchList) && storageWatchList?.length) {
                this._storeManager.symbol.setWatchList([...storageWatchList]);
            } 
        }
    }


}

export default SymbolService;