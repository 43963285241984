// Types
import storageManager from "modules/storage-manager";
import Dependencies from "shared/app/dependencies";
import { IndicatorFeedData, SymbolDetailedData, SymbolStatsFeedData } from "shared/symbol/types";
import { SupportResistance } from "store/slices/indicator-slice/types";
// Helpers

class IndicatorService {
    private readonly _storeManager;
    public analysis!: string;

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
    }

    // <SocketHandler>
    /** Description: Updates the indicator data. (WebSocket.IndicatorHub)
     * 
     * Indicators: Trend, Momentum
     */
    public updateIndicatorData = (data: IndicatorFeedData) => {
        this.updateTrendData(data);
        this.updateMomentumData(data);
    }

    /** Description: Updates the indicator data. (WebSocket.FeedHub:DataDetails) 
     * 
     * Indicators: Level, Signal, Support Resistance
    */
    public updateSymbolDetailsData = (data: SymbolDetailedData) => {
        if (data.symbol === this.analysis) {
            this.updateLevelData(data);
            this.updateSignalData(data);
        }
        
        this.updateSupportResistanceData(data);
    }
    // </SocketHandler>

    /** Description: Updates the level indicator data. */
    private updateLevelData = (data: SymbolStatsFeedData) => {
        this._storeManager.indicator.setLevel({
            dailyHigh: data.dailyHigh,
            weeklyHigh: data.weeklyHigh,
            monthlyHigh: data.monthlyHigh,
            dailyLow: data.dailyLow,
            weeklyLow: data.weeklyLow,
            monthlyLow: data.monthlyLow,
            last50BarByIndicator: data.last50BarByIndicator,
            last200BarByIndicator: data.last200BarByIndicator,
            dailyRange: data.dailyRange,
            yesterdayClose: data.yesterdayClose,
            yesterdayHigh: data.yesterdayHigh,
            yesterdayLow: data.yesterdayLow,
        });
    }

    /** Description: Updates the signal indicator data. */
    private updateSignalData = (data: SymbolStatsFeedData) => {
        this._storeManager.indicator.setSignal(data.signal);
    }

    /** Description: Updates the support resistance indicator data. */
    private updateSupportResistanceData = (data: SymbolStatsFeedData) => {
        const supportResistance = this._storeManager.indicator.getSupportResistance();
        this._storeManager.indicator.setSupportResistance({
            ...supportResistance,
            [data.symbol]: {
                xR4: data.xR4,
                xR3: data.xR3,
                xR2: data.xR2,
                xR1: data.xR1,
                xS4: data.xS4,
                xS3: data.xS3,
                xS2: data.xS2,
                xS1: data.xS1,
                xp: data.xp,
            } as SupportResistance
        })
    }

    /** Description: Updates the trend indicator data. */
    private updateTrendData = (data: IndicatorFeedData) => {
        const { trendValue5, trendValue15, trendValue30, trendValue60, trendValue240, trendValue1440 } = data;

        this._storeManager.indicator.setTrend({
            trendValue5,
            trendValue15,
            trendValue30,
            trendValue60,
            trendValue240,
            trendValue1440,
        });
    }

    /** Description: Updates the momentum indicator data. */
    private updateMomentumData = (data: IndicatorFeedData) => {
        const { mom5, mom15, mom30, mom60, mom240, mom1440 } = data;

        this._storeManager.indicator.setMomentum({
            mom5: mom5,
            mom15: mom15,
            mom30: mom30,
            mom60: mom60,
            mom240: mom240,
            mom1440: mom1440,
        });
    }

    public storeStorageValues = () => {
        this.storeStorageAnalysis();
    }

    private storeStorageAnalysis = () => {
        const storageValue = storageManager.preferences.get()?.analysis;
        if (storageValue) {
            const watchList = this._storeManager.symbol.getWatchList();
            
            const isFound = watchList.findIndex((symbol) => symbol === storageValue) !== -1;
            if (isFound) {
                this._storeManager.symbol.setAnalysis(storageValue);

            } else {
                storageManager.preferences.update({ analysis: undefined });
            }
            
        }
    }

    public setAnalysis = (symbol: string) => {
        this._storeManager.symbol.setAnalysis(symbol);
        storageManager.preferences.update({ analysis: symbol });
    }
}

export default IndicatorService;