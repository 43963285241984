import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { AppState } from "./types";
import { thunkService } from "services";

class AppThunk {
    public addCases = (builder: ActionReducerMapBuilder<AppState>) => {
        this.addAppConfigCase(builder);
    }

    /** Description: Adds the "getAppConfig" method cases to extra reducers in the App Slice.  */
    private addAppConfigCase = (builder: ActionReducerMapBuilder<AppState>) => {
        // Event: onPending
        builder.addCase(thunkService.app.getAppConfig.pending, (state, action) => {
            state.config = new StoreMiddlewareEntity({
                data: state.config.data,
                status: EntityStatus.PENDING,
                updateTime: Date.now(),
                message:null
            })
        });

        // Event: onRejected
        builder.addCase(thunkService.app.getAppConfig.rejected, (state, action) => {
            state.config = new StoreMiddlewareEntity({
                data: null,
                status: EntityStatus.FAILED,
                updateTime: Date.now(),
                message:action.error.message!
            })
        });

        // Event: onFulfilled
        builder.addCase(thunkService.app.getAppConfig.fulfilled, (state, action) => {
            const { language, ...appConfig } = action.payload as any;

            state.language = language;
            state.config = new StoreMiddlewareEntity({
                data: appConfig,
                status: EntityStatus.SUCCEEDED,
                updateTime: Date.now(),
                message: null
            })

        });
    }
}

const appThunk = new AppThunk();
export default appThunk;