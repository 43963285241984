import { createSlice } from "@reduxjs/toolkit";
import orderThunk from "./middleware";
// State
import orderPositionDealState from "./state";
// Types
import { OrderPositionDealReducers } from "./types";

const orderPositionDealSlice = createSlice({
    name: 'orderPositionDeal',
    initialState: orderPositionDealState,
    reducers: {
        setPositions(state, action) {
            state.positions = action.payload            
        },
        setOrders(state, action) {
            state.orders = action.payload;
        },
        setDeals(state, action) {
            state.deals = action.payload;
        }
    } as OrderPositionDealReducers,
    extraReducers(builder) {
        orderThunk.addCases(builder);
    },
})

export default orderPositionDealSlice;