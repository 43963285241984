import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
// Utils
import getTokenWithBearer from "shared/utils/getTokenWithBearer";

import { appConfig } from "app/config";


const transformOptions = (options: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const headers: AxiosRequestHeaders = {
        'Abp.Tenantid': appConfig.tenantId,
        ...options.headers,
    }

    // client platform
    headers.ClientPlatform = 'WebApp';

    // token
    const token = getTokenWithBearer();
    if (token) {
        headers.Authorization = token;
    }

    // platform code
    const platformCode = getPlatformCode();
    if (platformCode) {
        headers.PlatformCode = platformCode;
    }
    
    // account no
    const accountNo = getAccountNo();
    if (accountNo) {
        headers.AccountNo = accountNo;
    }

    options.headers = headers;
    return Promise.resolve(options);
}

export default transformOptions;