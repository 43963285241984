import React from 'react';
import { connect } from 'react-redux';
// Types
import { RootState } from 'store/types';
import storeManager from 'store/manager';
import { Op, PositionActions } from 'shared/trade/enums';
import useTranslation from 'modules/localization';
import { chartService, positionService } from 'services';
import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';
import { PositionDto } from '../../../api/service-proxies';
import { SymbolFeedData } from '../../../shared/symbol/types';

interface Props {
    data: Array<PositionDto>,
    symbolData: { [key: string]: SymbolFeedData },
    sortedColumn: SortedColumn,
    tradingViewTimeZone: string
}

const Positions = (props: Props) => {
    const { t } = useTranslation();

    const openModifyModal = (data: PositionDto) => storeManager.modal.setPositionModify({
        visibility: true,
        data: {
            position: data.position,
            symbol: data.symbol,
            type: data.type,
            volume: data.volume,
            digits: data.digits,
            priceOpen: data.priceOpen.toFixed(data.digits),
            priceSL: data.priceSL.toFixed(data.digits),
            priceTP: data.priceTP.toFixed(data.digits),
            frozenPrice: true
        }
    });

    const openCloseModal = (data: PositionDto) => {
        const marketData = props.symbolData.hasOwnProperty(data.symbol!) ? props.symbolData[data.symbol!] : null;
        
        if (marketData) {
            const { ask, bid } = marketData;

            storeManager.modal.setPositionClose({
                visibility: true,
                data: {
                    position: data.position,
                    price: (Op as any)[data.type] === Op.Buy? bid: ask, 
                    symbol: data.symbol,
                    type: data.type,
                    priceSL: data.priceSL,
                    priceTP: data.priceTP,
                    volume: data.volume, 
                }
            });
        }
    };

    const handleCloseMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        const rowElement = event.currentTarget.parentElement?.parentElement?.parentElement; 
        if (rowElement) {
            rowElement.classList.add('close');
            
            setTimeout(() => {
                if (rowElement) {
                    rowElement.classList.remove('close');
                }
    
            }, positionService.provider.ROW_TRANSACTION_ANIMATION_MS / 2);

        }
    }

    // <Calculations>
    const getTotal = (orderKey: keyof PositionDto) => {
        let calculation = 0;

        if (props.data) {
            props.data.forEach((order: PositionDto) => calculation += order[orderKey] as number);
        }

        return calculation === 0? "" :calculation.toFixed(2);
    }
    // </Calculations>

    const data = tableColumnSortHelper.getSortedData({ sortStateGetter: props.sortedColumn, data: props.data });

    return (
        <React.Fragment>
            <tr className="table-title">
                <td colSpan={12}><b>{t('Positions')}</b></td>
            </tr>
            {
                data?.map((data: PositionDto) => {
                    const openPrice = data.priceOpen.toFixed(data.digits);
                    const stopLoss = data.priceSL === 0 ? "" : data.priceSL.toFixed(data.digits);
                    const takeProfit = data.priceTP === 0 ? "" : data.priceTP.toFixed(data.digits);
                    const closePrice = data.priceCurrent.toFixed(data.digits);

                    const volume = data.volume.toFixed(2);
                    const swap = data.storage === 0 ? "" : data.storage.toFixed(2);
                    const profit = data.profit.toFixed(2);

                    return (
                        <tr key={data.position} id={`order-${data.position}`}>
                            <td>#{data.position}</td>
                            <td>{data.timeCreate.setZone(props.tradingViewTimeZone).toFormat('yyyy.LL.dd HH:mm:ss')}</td>
                            <td>{PositionActions[data.type]}</td>
                            <td>{volume}</td>
                            <td className="chart-button" onClick={() => chartService.changeSelectedTab(data.symbol!)}>
                                {data.symbol}
                                <i className="icofont-chart-line-alt"></i>
                            </td>
                            <td>{openPrice}</td>
                            <td>{stopLoss}</td>
                            <td>{takeProfit}</td>
                            <td>{closePrice}</td>
                            <td>{swap}</td>
                            <td>{profit}</td>
                            <td>
                                <div className="options">
                                    <button className="modify-button" onClick={() => openModifyModal(data)}>
                                        {t('Modify')}
                                        <i className="icofont-edit"></i>
                                    </button>
                                    <button className="delete-button" onClick={() => openCloseModal(data)} onMouseDown={handleCloseMouseDown}>
                                        {t('Close')}
                                        <i className="icofont-close-squared-alt"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }

            <tr>
                <td colSpan={8}></td>
                <td>
                    <strong>{t('TotalOrders')}</strong>
                </td>
                <td>
                    <strong>{getTotal('storage')}</strong>
                </td>
                <td>
                    <strong>{getTotal('profit')}</strong>
                </td>
                <td></td>
            </tr>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => {
    return ({
        data: state.orderPositionDeal.positions,
        symbolData: state.symbol.data,
        tradingViewTimeZone: state.account.tradingViewTimeZone
    })
}

export default connect(mapStateToProps)(Positions);