import React from 'react';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
import Api, { ApiTypes } from 'api';
// Components
import Modal from 'components/modal';
// Types
import { RootState } from 'store/types';
import { AlarmData } from 'store/slices/alarm-slice/types';
import { alarmService } from 'services';
import useTranslation from 'modules/localization';
import getSymbolDigit from 'shared/utils/getSymbolDigit';

type Alarm = ApiTypes.AlarmDto;

interface Props {
    alarmData: AlarmData
}

const AlarmListModal = (props: Props) => {
    const { t } = useTranslation();

    React.useEffect(() => {
        const getAlarms = async () => {
            await alarmService.getAll();
        }

        getAlarms();
        
    }, [])

    /** Description: Closes the current modal. */
    const handleClose = () => storeManager.modal.setAlarmList({ visibility: false, data: null });

    /** Description: Opens the "Modify Order" modal. */
    const handleModifyClick = (data: Alarm) => {
        storeManager.modal.setAlarmModify({ visibility: true, data })
    }
    
    /** Description: Opens the "Delete Order" modal. */
    const handleDeleteClick = (data: Alarm) => {
        storeManager.modal.setAlarmDelete({ visibility: true, data })
    }
    
    return (
        <Modal title="Alarms" id="alarm-list-modal" close={handleClose}>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>{t('Symbol')}</th>
                        <th>{t('Price')}</th>
                        <th>{t('Name')}</th>
                        <th>{t('Type')}</th>
                        <th>{t('Expiration')}</th>
                        <th className="options">{t('Options')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.alarmData.map((alarmData, index) => {
                            const no = index + 1;
                            const digit = getSymbolDigit(alarmData.symbol as string);
                            const condition = alarmData.condition;
                            const alarmType = Api.AlarmCondition[condition];
                            const price = alarmData.price.toFixed(digit!);

                            return (
                                <tr key={alarmData.id}>
                                    <td>{no}</td>
                                    <td>{alarmData.symbol}</td>
                                    <td>{price}</td>
                                    <td>{alarmData.alarmName}</td>
                                    <td>{alarmType}</td>
                                    <td>{alarmData.expiration?.toLocaleString()}</td>
                                    <td>
                                        <button className="modify" onClick={() => handleModifyClick(alarmData)}>{t('Modify')}</button>
                                        <button className="delete" onClick={() => handleDeleteClick(alarmData)}>{t('Close')}</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    alarmData: state.alarm.data
})

export default connect(mapStateToProps)(AlarmListModal);