import { OpType } from "shared/symbol/constants";
import { EnOrderType } from "../../api/service-proxies";


const getOrderType = (orderType: EnOrderType, opType: OpType) => {
    if (orderType === EnOrderType.OP_BUY) {
        // buy market
        if (opType === OpType.MARKET) {
            return EnOrderType.OP_BUY;

        // buy limit
        } else if (opType === OpType.LIMIT) {
            return EnOrderType.OP_BUY_LIMIT;
            
        // buy stop
        } else if (opType === OpType.STOP) {
            return EnOrderType.OP_BUY_STOP;

        }

    } else {
        // sell market
        if (opType === OpType.MARKET) {
            return EnOrderType.OP_SELL;

        // sell limit
        } else if (opType === OpType.LIMIT) {
            return EnOrderType.OP_SELL_LIMIT;
            
        // sell stop
        } else if (opType === OpType.STOP) {
            return EnOrderType.OP_SELL_STOP;
            
        }
        
    }
}

export default getOrderType;