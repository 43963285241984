import PageLoader from "components/page-loader";
import { EntityStatus } from "shared/global/enums";
import getTokenWithBearer from "shared/utils/getTokenWithBearer";
import storeManager from "store/manager"

class PageLoaderHelper {
    /** Description: Manages the request status rendering for client. */
    public check = () => {
        let renderingComponent;
        
        const token = getTokenWithBearer();
        if (token) {
          renderingComponent = this.checkLoginInformations();

        } else {
          renderingComponent =this.checkAppConfig();  
        }

        return renderingComponent;
    }

    /** Description: Renders the app configuration request status to client. */
    private checkAppConfig = () => {
        const { status, data } = storeManager.app.getConfig();

        if (status === EntityStatus.IDLE || status === EntityStatus.PENDING) {
            return (
                <PageLoader message='The application configuration is checking...' />
            ); 
        
          } else if (status === EntityStatus.FAILED) {
            return (
              <div>Error: {data}</div>
            );
        }

        return null;
    }

    /** Description: Renders the login information request status to client. */
    private checkLoginInformations = () => {
        const { status, message } = storeManager.account.getLoginInformations();
        
        if (status === EntityStatus.IDLE || status === EntityStatus.PENDING) {
            return (
                <PageLoader message='The login configuration is checking...' />
            );
            
          } else if (status === EntityStatus.FAILED) {
            return (
                <div>Error: {message}</div>
            )
        }

        return null;
    }
}

const pageLoaderHelper = new PageLoaderHelper();
export default pageLoaderHelper;