export enum SymbolFeedDirections {
    UP = 'up-direction',
    DEFAULT = 'default-direction',
    DOWN = 'down-direction'
}

export enum OpType {
    LIMIT = 'LIMIT',
    MARKET = 'MARKET',
    STOP = 'STOP',
}

export enum ChartModes {
    MULTI,
    SINGLE
}