import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { AppState } from "./types";

const appState: AppState = {
    language: null, // info - setted by config
    location: null,
    mode: null,
    theme: null,
    oneClickTrading: null,
    bottomFieldHeight: 280,
    config: new StoreMiddlewareEntity({ data: null, status: EntityStatus.IDLE, updateTime: null, message:null }),
}

export default appState;