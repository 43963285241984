import Api, { ApiTypes } from "api";
import { toast } from 'react-toastify';
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import Dependencies from "shared/app/dependencies";
import { ApiResponse } from "../../shared/api/constants";

class PositionProvider {
    private readonly _storeManager;
    private readonly _positionService;
    public readonly ROW_TRANSACTION_ANIMATION_MS = 3000;

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;        
        this._positionService = new Api.PositionServiceProxy();
    }

    /** Description: Edits an position. */
    public async modify() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const data = this._storeManager.modal.getPositionModify().data;

        const inputDto = new Api.ModifyPositionInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            ticket: data.position,
            stopLoss: data.priceSL,
            takeProfit: data.priceTP,
            comment: "",
            symbol: data.symbol
        });

        const response: ApiResponse<ApiTypes.ModifyPositionOutputDto> = await this._positionService.modify(inputDto) as any;
        if (response.success) {
            const position = response.data.position;

           // Row transaction animation
            setTimeout(() => {
                const positionRow = document.getElementById(`position-${position.position}`);
                if (positionRow) {
                    positionRow.classList.add('modify');
                }

                setTimeout(() => {
                    if (positionRow) {
                        positionRow.classList.remove('modify');
                    }

                }, this.ROW_TRANSACTION_ANIMATION_MS);
                
            }, 0); // TODO: should be outside of setTimeout method 


        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    /** Description: Closes an position. */
    public async close() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const data = this._storeManager.modal.getPositionClose().data;

        const inputDto = new Api.ClosePositionInputDto({
            price: data.price,
            accountNo: accountNo,
            platformCode: platformCode,
            ticket: data.position,
            symbol: data.symbol,
            comment: "",
            volume: data.volume
        })

        const response: ApiResponse<ApiTypes.ClosePositionOutputDto> = await this._positionService.close(inputDto) as any;
        if (response.success) {
            const position = response.data.position;
            const positions = this._storeManager.orderPositionDeal.getPositions();
            this._storeManager.orderPositionDeal.setPositions([...positions].filter((_position) => _position.position !== position.position));

        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    /** Description: Closes all of positions. */
    public async closeAll(input: any) {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const inputDto = new Api.CloseAllPositionsInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            operationType: input.operationType,
            comment: "",
            symbol: input.closeSymbolType
        })

        const response: ApiResponse<ApiTypes.CloseAllPositionsOutputDto> = await this._positionService.closeAll(inputDto) as any;
        if (response.success && response.data.succesfull?.length) {
            const succespositions = response.data.succesfull;
            const positions = this._storeManager.orderPositionDeal.getPositions();
            this._storeManager.orderPositionDeal.setOpen([...positions].filter(position => !succespositions?.some((succesposition) => succesposition.position === position.position)));
        } else {
            toast('Multiple close failed.');
        }
    }
}

export default PositionProvider;